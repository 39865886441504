.container{
    width: 607px;
    height: 829px;
    margin: auto;
    margin-top: 75px;
    border-radius: 12px;
    background-image: linear-gradient(180deg, #130754 0%, #3b2f80 100%   );
}

.top-bar{
    display: flex;
    justify-content: center;
    gap: 14px;
    padding-top: 60px;
}

.top-bar input{
    display: flex;
    width: 362px;
    height: 78px;
    background: #ebfffc;
    border: none;
    outline: none;
    border-radius: 40px;
    padding-left: 40px;
    color: #626262;
    font-size: 20px;
    font-weight: 400;
}

.search-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 78px;
    background: #ebfffc;
    border-radius: 40px;
    cursor: pointer;
}

.weather-image{
    margin-top: 29px;
    display: flex;
    justify-content: center;
}

.weather-temp{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 120px;
    font-weight: 400;
}

.weather-location{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 60px;
    font-weight: 400;
}

.data-container{
    margin-top: 50px;
    color: white;
    display: flex;
    justify-content: center;
}

.element{
    margin: auto;
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.data{
    font-size: 34px;
    font-weight: 400;

}

.text{
    font-size: 20px;
    font-weight: 400;
}

.icon{
    margin-top: 10px;
}